import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function PageContainer(props) {
  const { children, ...otherProps } = props;
  const classes = useStyles();

  return (
    <div {...otherProps} className={clsx(classes.root, otherProps.className)}>
      {children}
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
PageContainer.defaultProps = {
};


const useStyles = makeStyles((/* theme */) => ({
  root: {
    // TODO: Check this
    //overflowY: 'hidden' // BUGFIX: overflowing content breaks responsive layout
  },
}));
