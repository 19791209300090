import * as yup from 'yup';

export default yup.object().shape({
  salutation: yup.string().required('Anrede ist erforderlich'),
  firstname: yup.string().min(1).max(100).required('Vorname ist erforderlich'),
  lastname: yup.string().min(1).max(100).required('Nachname ist erforderlich'),
  email: yup.string().min(1).max(255).email().required('E-Mail ist erforderlich'),
  phone: yup.string().min(1).max(255).required('Telefon ist erforderlich'),
  message: yup.string().max(5000).nullable(),
  topic: yup.string().nullable(),
  //isTermsAndPrivacyAccepted: yup.bool().isTrue()
});
