import { useMediaQuery, useTheme } from '@material-ui/core';

export default function PageInsetTop() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={{ height: isMobile ? 120 : 180 }} />
  );
}
