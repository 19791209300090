import {
  Box,
  ButtonGroup,
  Fade,
  Grid,
  Button as MuiButton,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { gql, useQuery } from '@apollo/client';

import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';

export default function InterestRateDevelopment() {
  const classes = useStyles();
  const theme = useTheme();
  const [dateRange, setDateRange] = useState(DATE_CATEGORIES[DATE_CATEGORIES.length - 1].value);

  const { error, data } = useQuery(FETCH_DATA, {
    variables: {
      fixedInterestRateYears: 'Ten',
      minDate: dateRange.min.toFormat('yyyy-MM-dd'),
      maxDate: DateTime.now().toFormat('yyyy-MM-dd')
    }
  });

  if (error) {
    throw error;
  }

  const items = data?.interests.map(item => ({
    id: item.id,
    key: DateTime.fromFormat(item.date, 'yyyy-MM-dd').toSeconds(),
    value: item.interest_rate
  })) ?? [];
  const interestRateInYears = 10;

  const handleSetDateRange = (value) => () => {
    setDateRange(value);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography gutterBottom variant="subtitle2">
            Zinsentwicklung {dateRange.title}*
          </Typography>
        </Grid>
        <Grid>
          <Typography gutterBottom variant="caption">
            {interestRateInYears} Jahre Sollzinsbindung
          </Typography>
        </Grid>
      </Grid>
      <div style={styles.chartContainer}>
        <Fade
          in={items.length > 0}
          mountOnEnter
          unmountOnExit
        >
          <ResponsiveContainer
            width={styles.chartContainer.width}
            height={styles.chartContainer.height}
          >
            <LineChart data={items}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="key"
                domain={['dataMin', 'dataMax']}
                scale="time"
                tickLine={false}
                interval="preserveEnd"
                tickFormatter={(d) => DateTime.fromSeconds(d).setLocale('de').toFormat('MMM yy')}
              />
              <YAxis
                type="number"
                dataKey="value"
                unit="%"
                axisLine={false}
                tickLine={false}
                domain={['dataMin - 0.1', 'dataMax + 0.1']}
                tickFormatter={(i) => i.toLocaleString('de', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              />
              <Tooltip
                content={
                  ({ active, payload }) => (active && payload && payload.length)
                    ? (
                      <div className={classes.chartTooltipContainer}>
                        <Typography
                          variant="caption"
                          className={classes.chartTooltip}
                        >
                          {`${payload[0].payload.value.toLocaleString('de', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}%`}
                        </Typography>
                      </div>
                    )
                    : null
                }
              />
              <Line
                key="value"
                dataKey="value"
                type="step"
                dot={false}
                stroke={theme.palette.primary.main}
              >
              </Line>
            </LineChart>
          </ResponsiveContainer>
        </Fade>
      </div>
      <Box>
        <ButtonGroup
          fullWidth
          variant="text"
        >
          {DATE_CATEGORIES.map(item => (
            <MuiButton
              key={item.value.key}
              color={
                dateRange.key === item.value.key
                  ? 'primary'
                  : 'default'
              }
              onClick={handleSetDateRange(item.value)}
            >
              {item.name}
            </MuiButton>
          ))}
        </ButtonGroup>
      </Box>
      <Box mt={3}>
        <Typography variant="caption" className={classes.legalNotice}>
          *Die dargestellten Zinssätze basieren auf den Daten der durchschnittlichen Zinsen für Annuitätendarlehen unserer Bankpartner der vergangenen Jahre. Trotz sorgfältiger Prüfung können wir für die dargestellten Werte keine Haftung oder Gewähr übernehmen.
        </Typography>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  chartTooltipContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  chartTooltip: {
    color: theme.palette.primary.contrastText,
  },
  legalNotice: {
    opacity: 0.5,
    textAlign: 'justify',
    hyphens: 'auto'
  }
}));

const styles = {
  chartContainer: {
    width: '100%',
    height: 300
  }
};

const DATE_CATEGORIES = [
  /*{
    name: '1 Monat',
    value: {
      key: 'months-1',
      min: DateTime.now().minus({ months: 1 }),
      title: 'des letzten Monats'
    }
  },
  {
    name: '3 Monate',
    value: {
      key: 'months-3',
      min: DateTime.now().minus({ months: 3 }),
      title: 'der letzten 3 Monate'
    }
  },*/
  {
    name: '6 Monate',
    value: {
      key: 'months-6',
      min: DateTime.now().minus({ months: 6 }),
      title: 'der letzten 6 Monate'
    }
  },
  {
    name: '1 Jahr',
    value: {
      key: 'years-1',
      min: DateTime.now().minus({ years: 1 }),
      title: 'des letzten Jahres'
    }
  },
  {
    name: '5 Jahre',
    value: {
      key: 'years-5',
      min: DateTime.now().minus({ years: 5 }),
      title: 'der letzten 5 Jahre'
    }
  },
  {
    name: '10 Jahre',
    value: {
      key: 'years-10',
      min: DateTime.now().minus({ years: 10 }),
      title: 'der letzten 10 Jahre'
    }
  },
  {
    name: '15 Jahre',
    value: {
      key: 'years-15',
      min: DateTime.now().minus({ years: 15 }),
      title: 'der letzten 15 Jahre'
    }
  }
];

const FETCH_DATA = gql`
query Data($fixedInterestRateYears: String!, $minDate: String!, $maxDate: String!) {
  interests(sort: "date:asc", where: {
    fixed_interest_rate_years: $fixedInterestRateYears
    date_gte: $minDate
    date_lte: $maxDate
  }) {
    id
    date
    interest_rate
  }
  interestsConnection(sort: "date:asc", where: {
    fixed_interest_rate_years: $fixedInterestRateYears
  }) {
    aggregate {
      count
    }
  }
  min: interests(sort: "date:asc", limit: 1, where: {
    fixed_interest_rate_years: $fixedInterestRateYears
  }) {
    date
  }
  max: interests(sort: "date:desc", limit: 1, where: {
    fixed_interest_rate_years: $fixedInterestRateYears
  }) {
    date
  }
}
`;
