import PageContainer from '../organisms/PageContainer';
import PropTypes from 'prop-types';
import React from 'react';

export default function DefaultTemplate(props) {
  const { children, ...otherProps } = props;

  return (
    <PageContainer {...otherProps}>
      {children}
    </PageContainer>
  );
}

DefaultTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
DefaultTemplate.defaultProps = {
};
