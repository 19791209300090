import * as yup from 'yup';

import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';
import { NUMBER_OF_BANKS } from 'config';
import BrandAttentionBar from '../organisms/BrandAttentionBar';
import Container from '../atoms/Container';
import InterestCalculator from '../organisms/InterestCalculator';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';
import { useRouter } from 'next/router';

export default function InterestCalculatorBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const router = useRouter();
  const { elementData } = useContentElement(
    data,
    InterestCalculatorBlock.dataSchema
  );

  const {
    employee,
    channel
  } = elementData;

  const handleSubmit = async (values) => {
    try {
      const params = new URLSearchParams({
        ...values
      });

      if (employee) {
        params.append(
          'owner',
          employee.deal_assignee?.id ?? employee.id
        );
      }

      if(channel) {
        params.append('channel', channel);
      }

      router.push(`/finanzierung-anfragen?${params.toString()}`);
    }
    catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box mb={5}>
          <Typography variant="h3" gutterBottom align="center">
            Zins-Check
          </Typography>
          <Typography align="center">
            Vergleichen Sie jetzt über {NUMBER_OF_BANKS} Banken, Sparkassen &amp; Versicherungen. Gemeinsam finden wir die passende Lösung für Ihre Traum-Immobilie!
          </Typography>
        </Box>
        <Paper className={classes.paper} elevation={10}>
          <InterestCalculator onSubmit={handleSubmit} />
          <div className={classes.footer}>
            <BrandAttentionBar
              isProvenExpertVisible
              isSloganVisible
            />
          </div>
        </Paper>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  container: {
    maxWidth: 900
  },
  paper: {
    padding: theme.spacing(3),
    borderWidth: 4,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main
  },
  footer: {
    borderTopWidth: 0,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.tertiary.main,
    marginTop: theme.spacing(3),
  }
}));

InterestCalculatorBlock.typeName = 'ComponentContentContentBlockInterestCalculator'; // Strapi element type
InterestCalculatorBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired
};
InterestCalculatorBlock.dataSchema = yup.object().shape({
});
InterestCalculatorBlock.graphQlSchema = `
... on ${InterestCalculatorBlock.typeName} {
  id
}
`;
