import * as yup from 'yup';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { FiArrowLeft, FiCalendar, FiPercent, FiX } from 'react-icons/fi';
import { MediaElement, useContentElement } from '@plugins/next-cms-core';
import { collapsedHeightDesktop, collapsedHeightMobile } from '../organisms/Header';
import { forwardRef, useEffect, useState } from 'react';

import AppointmentRequestForm from '../../forms/AppointmentRequestForm';
import Container from '../atoms/Container';
import Link from '../atoms/Link';
import PropTypes from 'prop-types';
import { SwitchTransition } from 'react-transition-group';
import getConfig from 'next/config';
import { makeStyles } from '@material-ui/styles';
import { zIndices } from '../../theme';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

const { publicRuntimeConfig } = getConfig();

export default function AppointmentRequestFormBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isFormVisible, setFormVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState('form');
  const [hubSpotInstanceId, setHubSpotInstanceId] = useState(null);
  const [hubSpotUserId, setHubSpotUserId] = useState(null);
  const { elementData } = useContentElement(
    data,
    AppointmentRequestFormBlock.dataSchema
  );
  const { data: hubSpotInstanceData } = useQuery(FETCH_HUBSPOT_INSTANCE);
  const [hubSpotInstanceForEmployee] = useLazyQuery(FETCH_HUBSPOT_INSTANCE_FOR_USER);

  const stickThresholdTop = parseInt((isMobile
      ? collapsedHeightMobile
      : collapsedHeightDesktop
  ).replace('px', ''), 10) + (isMobile ? 40 : 0) - 2;
  const {
    modelRequestFormTitle: title,
    modelRequestFormSubtitle: subtitle,
    modelRequestFormPreEnteredMessage: pre_entered_message,
    modelRequestFormButtonInfoText: button_info_text,
    modelRequestFormButtonLabel: button_label,
    directAppointmentUrl,
    leadOwner,
    targetEmail,
    employees
  } = elementData;
  const hideAppointmentButton = leadOwner ? !directAppointmentUrl : false;

  const handleToggleForm = () => {
    setDialogContent('form');
    setFormVisible(!isFormVisible);
  };

  useEffect(() => {
    document.addEventListener('onShowAppointmentForm', handleToggleForm);

    if (leadOwner) {
      hubSpotInstanceForEmployee({
        variables: {
          id: leadOwner
        }
      }).then(({ data }) => {
        setHubSpotInstanceId(data.hubSpotInstanceForEmployee);
        setHubSpotUserId(data.hubSpotUserIdForEmployee);
      });
    }

    return () => {
      document.removeEventListener('onShowAppointmentForm', handleToggleForm);
    };
  }, []);
  useEffect(() => {
    if (hubSpotInstanceData?.hubSpotInstances.length > 0) {
      setHubSpotInstanceId(hubSpotInstanceData.hubSpotInstances[0].instanceId);
    }
  }, [hubSpotInstanceData?.hubSpotInstances.length]);

  const handleShowForm = () => {
    setDialogContent('form');
  };
  const handleSelectOnlineAppointment = () => {
    if (directAppointmentUrl) {
      window.open(directAppointmentUrl, '_blank').focus();
      handleToggleForm();
    } else {
      setDialogContent('select-staff');
    }
  };
  const handleSelectStaff = () => {
    handleToggleForm();
  };

  return (
    <div
      className={classes.root}
      style={{ top: stickThresholdTop }}
    >
      <Container className={classes.container}>
        <Grid container spacing={3} alignItems="center">
          <Hidden smDown>
            <Grid item xs={12} md={6}>
              <Typography
                component="span"
                variant="h5"
                color="secondary"
                className={classes.title}
              >
                {title}
              </Typography>
              {' '}
              <Typography
                component="span"
                color="secondary"
                className={classes.secondaryText}
              >
                {subtitle}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box mr={3}>
                <Typography
                  component="span"
                  color="secondary"
                  className={classes.secondaryText}
                >
                  {button_info_text || 'Termin vereinbaren & Zinsen-Check'}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className={classes.toggleFormButton}
                onClick={handleToggleForm}
              >
                {button_label || 'Anfragen'}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          scroll="body"
          maxWidth="lg"
          open={isFormVisible}
          onClose={handleToggleForm}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              {button_label || 'Beratungstermin vereinbaren'}
            </DialogTitle>
            <Box pr={1}>
              <IconButton onClick={handleToggleForm}>
                <FiX/>
              </IconButton>
            </Box>
          </Box>
          <DialogContent style={{ minHeight: 400, overflowX: 'hidden' }}>
            <SwitchTransition>
              <Slide
                key={dialogContent}
                direction={dialogContent === 'form' ? 'right' : 'left'}
              >
                {dialogContent === 'form'
                  ? (
                    <Form
                      hideAppointmentButton={hideAppointmentButton}
                      targetEmail={targetEmail}
                      leadOwner={leadOwner}
                      hubspotInstanceId={hubSpotInstanceId}
                      hubSpotUserId={hubSpotUserId}
                      pre_entered_message={pre_entered_message}
                      onSelectOnlineAppointment={handleSelectOnlineAppointment}
                    />
                  ) : (
                    <StaffSelect
                      classes={classes}
                      items={employees}
                      onRequestClose={handleToggleForm}
                      onGoBack={handleShowForm}
                      onSelectStaff={handleSelectStaff}
                    />
                  )}
              </Slide>
            </SwitchTransition>
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
}

// eslint-disable-next-line react/display-name
const Form = forwardRef((props, ref) => {
  const {
    hubspotInstanceId,
    hubSpotUserId,
    hideAppointmentButton,
    targetEmail,
    leadOwner,
    pre_entered_message,
    onSelectOnlineAppointment
  } = props;

  const leadTargetUrl = new URL(
    '/finanzierung-anfragen',
    publicRuntimeConfig.PUBLIC_URL
  );

  if (leadOwner) {
    leadTargetUrl.searchParams.append('owner', leadOwner);
  }

  return (
    <div ref={ref}>
      <Box>
        <Grid container spacing={3}>
          {!hideAppointmentButton && (
            <Grid item xs={12} sm="auto">
              <Button
                fullWidth
                color="primary"
                variant="contained"
                startIcon={
                  <FiCalendar/>
                }
                onClick={onSelectOnlineAppointment}
              >
                Beratungstermin vereinbaren
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm="auto">
            <Link href={leadTargetUrl.toString()}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                component="a"
                startIcon={
                  <FiPercent/>
                }
              >
                Zinsen berechnen
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box mt={5} mb={3}>
        <Divider/>
      </Box>
      <Box mb={3}>
        <Typography variant="h6">
          Individuelle Anfrage
        </Typography>
        <AppointmentRequestForm
          hubspotInstanceId={hubspotInstanceId}
          hubSpotUserId={hubSpotUserId}
          targetEmail={targetEmail}
          message={pre_entered_message}
          formFields={{}}
        />
      </Box>
    </div>
  );
});

// eslint-disable-next-line react/display-name
const StaffSelect = forwardRef((props, ref) => {
  const {
    classes,
    items,
    onGoBack,
    onSelectStaff,
    onRequestClose
  } = props;

  return (
    <Box ref={ref} mb={2}>
      <Box display="flex" alignItems="center" mb={3}>
        <Box mr={1}>
          <IconButton onClick={onGoBack}>
            <FiArrowLeft/>
          </IconButton>
        </Box>
        <Typography variant="h5">
          Wähle deinen Berater
        </Typography>
      </Box>
      <Grid container spacing={5}>
        {items.map(item => (
          <Grid key={item.id} item xs={16} sm={6} md={3}>
            <Employee
              item={item}
              classes={classes}
              onRequestClose={onRequestClose}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

function Employee({ item, classes, onRequestClose }) {
  return (
    <Card>
      <Link href={item.action_appointment ?? '#'}>
        <CardActionArea
          component="a"
          target="_blank"
          onClick={onRequestClose}
        >
          <MediaElement
            isFluid
            data={item.media}
            alt={item.name}
            className={classes.cardImage}
          />
          <CardContent>
            <Box mb={1}>
              <Typography variant="subtitle1" component="h4">
                {item.name}
              </Typography>
              {item.position && (
                <Typography variant="subtitle2" component="h4">
                  {item.position}
                </Typography>
              )}
            </Box>
            <Box mt={3}>
              <Button
                fullWidth
                startIcon={<FiCalendar/>}
              >
                Termin vereinbaren
              </Button>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.tertiary.main,
    position: 'sticky',
    zIndex: zIndices.modelRequestForm,
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  formContainerStuck: {
    marginBottom: 300
  },
  title: {
    fontWeight: '900'
  },
  secondaryText: {
    //opacity: 0.75
  },
  toggleFormButton: {
    minWidth: 140
  },
  cardImage: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}));

AppointmentRequestFormBlock.typeName = 'ComponentContentContentBlockAppointmentRequestForm'; // Strapi element type
AppointmentRequestFormBlock.propTypes = {
  data: PropTypes.shape({
    modelRequestFormTitle: PropTypes.string,
    modelRequestFormSubtitle: PropTypes.string,
    modelRequestFormPreEnteredMessage: PropTypes.string,
    directAppointmentUrl: PropTypes.string,
    leadOwner: PropTypes.string,
    targetEmail: PropTypes.string,
  }).isRequired
};
AppointmentRequestFormBlock.dataSchema = yup.object().shape({
  modelRequestFormTitle: yup.string().nullable(),
  modelRequestFormSubtitle: yup.string().nullable(),
  modelRequestFormPreEnteredMessage: yup.string().nullable(),
  directAppointmentUrl: yup.string().nullable(),
  leadOwner: yup.string().nullable(),
  targetEmail: yup.string().nullable(),
});
AppointmentRequestFormBlock.graphQlSchema = `
... on ${AppointmentRequestFormBlock.typeName} {
  id
  modelRequestFormTitle: title
  modelRequestFormSubtitle: subtitle
  modelRequestFormPreEnteredMessage: pre_entered_message
  modelRequestFormButtonInfoText: button_info_text
  modelRequestFormButtonLabel: button_label
  employees {
    id
    slug
    name
    position
    action_appointment
    media {
      ${MediaElement.graphQlSchema}
    }
  }
}
`;

const FETCH_HUBSPOT_INSTANCE = gql`
  query HubSPotInstance {
    hubSpotInstances(where: { isDefault: true }) {
      instanceId
    }
  }
`;

const FETCH_HUBSPOT_INSTANCE_FOR_USER = gql`
  query FetchHubSpotInstanceForUser($id: ID!) {
    hubSpotUserIdForEmployee(id: $id)
    hubSpotInstanceForEmployee(id: $id)
  }
`;
