import { Box, Button, FormControl, Grid, InputLabel, FormHelperText, MenuItem, Typography } from '@material-ui/core';
import { FormState, useFormState, useSendEmail } from '@lib';

import { Alert } from '@material-ui/lab';
import FORM_INITIAL_VALUES from './initialValues';
import { Formik } from 'formik';
import Link from '@components/atoms/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@components/atoms/Select';
import TextInput from '@components/atoms/TextInput';
import { formikInjectedPropsTypes } from '@forms/propTypes';
import getConfig from 'next/config';
import { getUtmCampaignIfAvailable } from '@lib/utmCampaign';
import { useStyles } from './styles';
import validationSchema from './validate';

const { publicRuntimeConfig } = getConfig();

export default function AppointmentRequestForm(props) {
  const {
    hubspotInstanceId,
    hubSpotUserId,
    targetEmail,
    message,
    formFields
  } = props;

  if (!hubspotInstanceId) {
    throw new Error('"hubspotInstanceId" must be defined.');
  }

  const classes = useStyles();
  const {
    formState,
    handleGraphQlResult,
    setFormSubmitSucceed,
    setFormSubmitError,
    resetFormState
  } = useFormState();
  const sendEmail = useSendEmail();

  const handleSubmit = async (values) => {
    try {
      const campaign = getUtmCampaignIfAvailable();

      if (values.topic === 'Finanzierungsberatung'
        || values.topic === 'Bausparberatung'
        || values.topic === 'Kreditprüfung') {
        const lead = {
          source: campaign?.utm_source ?? 'BauDarlehen24',
          channel: campaign?.utm_medium ?? 'CONTACT_FORM',
          leadOwner: hubSpotUserId,
          contact: {
            salutation: values.salutation,
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone,
            message: `${values.topic}\n${values.source}\n\n${values.message}`
          },
        };

        const response = await fetch(`${publicRuntimeConfig.API_ENDPOINT}/hub-spot-instances/${hubspotInstanceId}/import-lead`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(lead)
        });

        if (!response.ok) {
          throw new Error(await response.text());
        }

        setFormSubmitSucceed();
      } else {
        const data = {
          to: targetEmail ?? publicRuntimeConfig.EMAIL_DEFAULT_TO,
          replyTo: values.email,
          bcc: 'info@dekay.dev',
          templateId: 'd-c80da8f8f00641459173d0707a92c458',
          dynamicTemplateData: {
            ...values,
            ...campaign,
            url: window.location.href,
          }
        };

        const result = await sendEmail(data);
        handleGraphQlResult(result);
      }
    } catch (e) {
      console.error(e);
      setFormSubmitError(e);
    }
  };

  switch (formState) {
    case FormState.INITIAL:
      return (
        <Formik
          initialValues={{
            ...FORM_INITIAL_VALUES,
            message: message ?? ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikBag) => (
            <Form
              formFields={formFields}
              classes={classes}
              {...formikBag}
            />
          )}
        </Formik>
      );
    case FormState.SUCCESS:
      return (
        <Box my={3}>
          <Alert
            severity="success"
            action={
              <Button onClick={resetFormState}>
                Neue Anfrage
              </Button>
            }
          >
            Vielen Dank für Ihre Anfrage. Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.
          </Alert>
        </Box>
      );
    case FormState.ERROR:
      return (
        <div>
          Error
        </div>
      );
  }
}

AppointmentRequestForm.propTypes = {
  message: PropTypes.string,
  formFields: PropTypes.object
};
AppointmentRequestForm.defaultProps = {
  message: null
};

function Form(props) {
  const {
    classes,
    formFields,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    dirty,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box my={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={5}>
            <Box>
              <FormControl
                color="primary"
                variant="outlined"
                fullWidth
              >
                <InputLabel id="form-salutation">
                  Anrede
                </InputLabel>
                <Select
                  labelId="form-salutation"
                  value={values.salutation}
                  onChange={(e) => setFieldValue('salutation', e.target.value)}
                >
                  <MenuItem value="Frau">
                    Frau
                  </MenuItem>
                  <MenuItem value="Herr">
                    Herr
                  </MenuItem>
                </Select>
                {errors.salutation && (
                  <FormHelperText error={touched.salutation}>
                    {errors.salutation}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mt={2}>
              <TextInput
                fullWidth
                name="firstname"
                label="Vorname"
                value={values.firstname}
                error={errors.firstname && touched.firstname}
                helperText={errors.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                fullWidth
                name="lastname"
                label="Nachname"
                value={values.lastname}
                error={errors.lastname && touched.lastname}
                helperText={errors.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                fullWidth
                name="email"
                label="E-Mail"
                value={values.email}
                error={errors.email && touched.email}
                helperText={errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                fullWidth
                name="phone"
                label="Telefon / Mobil (optional)"
                value={values.phone}
                error={errors.phone && touched.phone}
                helperText={errors.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mt={4}>
              <FormControl
                color="primary"
                variant="outlined"
                fullWidth
              >
                <InputLabel id="form-source">
                  Wie haben Sie von uns erfahren? (optional)
                </InputLabel>
                <Select
                  labelId="form-source"
                  value={values.source}
                  onChange={(e) => setFieldValue('source', e.target.value)}
                >
                  <MenuItem value="Weiterempfehlung">
                    Weiterempfehlung
                  </MenuItem>
                  <MenuItem value="Facebook">
                    Facebook
                  </MenuItem>
                  <MenuItem value="Instagram">
                    Instagram
                  </MenuItem>
                  <MenuItem value="Messe">
                    Messe
                  </MenuItem>
                  <MenuItem value="Radio">
                    Radio
                  </MenuItem>
                  <MenuItem value="Sonstiges">
                    Sonstiges
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box>
              <FormControl
                color="primary"
                variant="outlined"
                fullWidth
              >
                <InputLabel id="form-topic">
                  Welches Thema interessiert Sie?
                </InputLabel>
                <Select
                  labelId="form-topic"
                  value={values.topic}
                  onChange={(e) => setFieldValue('topic', e.target.value)}
                >
                  <MenuItem value="Finanzierungsberatung">
                    Finanzierungsberatung
                  </MenuItem>
                  <MenuItem value="Bausparberatung">
                    Bausparberatung
                  </MenuItem>
                  <MenuItem value="Kreditprüfung">
                    Kreditprüfung
                  </MenuItem>
                  <MenuItem value="Sonstiges">
                    Sonstiges
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={2}>
              <TextInput
                fullWidth
                name="message"
                label="Nachricht"
                multiline
                value={values.message}
                error={errors.message && touched.message}
                helperText={errors.message}
                inputProps={{
                  className: classes.messageInput
                }}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mt={2}>
              {/*<FormControlLabel
                label={
                  <Typography variant="caption">
                    Mit Art, Umfang und Zweck der Erhebung, Verwendung und Berabeitung meiner personenbezogenen Daten gemäß der Datenschutzerklärung erkläre ich mich einverstanden und willige darin ein.
                  </Typography>
                }
                control={
                  <Checkbox
                    color="primary"
                    checked={values.isTermsAndPrivacyAccepted}
                    onChange={(e) => setFieldValue('isTermsAndPrivacyAccepted', e.target.checked)}
                  />
                }
              />*/}
              <Typography>
                Die Hinweise zum
                {' '}
                <Link href="/datenschutz">
                  <a target="_blank">
                    Datenschutz
                  </a>
                </Link>
                {' '}
                habe ich zur Kenntnis genommen.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box pt={3} display="flex" justifyContent="center">
        <Button
          disabled={!dirty || !isValid || isSubmitting}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          style={{ maxWidth: 300 }}
        >
          Absenden
        </Button>
      </Box>
    </form>
  );
}

Form.propTypes = {
  ...formikInjectedPropsTypes,
  hubspotInstanceId: PropTypes.number,
};
