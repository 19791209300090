import * as yup from 'yup';

import { Avatar, Box, Divider, Hidden, Typography } from '@material-ui/core';
import { ColorScheme, useContentElement } from '@plugins/next-cms-core';
import { NetworkStatus, gql, useQuery } from '@apollo/client';

import BrandAttentionBar from '../organisms/BrandAttentionBar';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Link from '../atoms/Link';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/styles';
import trim from 'lodash/trim';

export default function CustomerTestimonialsBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    CustomerTestimonialsBlock.dataSchema
  );

  const {
    color_scheme,
    employee
  } = elementData;
  const tags = (elementData.tags || '')
    .split(',')
    .map(x => trim(x))
    .filter(x => !isEmpty(x));
  const where = {};

  if (tags && tags.length > 0) {
    where.tag_in = tags;
  }

  if (employee) {
    where.employee = {
      id: employee.id
    };
  }

  // Additional data
  const { error, data: additionalData, loading, networkStatus } = useQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
    variables: {
      where,
      sort: 'tag:asc, index:desc'
    }
  });

  if (error) {
    throw error;
  }

  const isLoading = loading || networkStatus < NetworkStatus.ready;
  const items = additionalData?.customertestimonials ?? [];
  const colorScheme = ColorScheme.getColorScheme(color_scheme, 'default');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  const colorMapped = MUI_COLOR_MAPPING[colorScheme];
  const cssColorMappedClass = `${colorMapped[0].toUpperCase()}${colorMapped.substring(1)}`;

  if (!isLoading && items.length === 0) {
    return null;
  }

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Container className={classes.container}>
        <Typography
          variant="h3"
          component="h2"
          align="center"
          color={colorMapped}
          className={classes.title}
        >
          Kundenstimmen
        </Typography>
        {isLoading && (
          <>
            {Array.from(Array(3).keys()).map((_, index) => (
              <Box key={index} display="flex" alignItems="center" mb={5}>
                <Box flex={1}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Box>
                <Box ml={3}>
                  <Skeleton variant="circle" width={100} height={100} />
                </Box>
              </Box>
            ))}
          </>
        )}
        {!isLoading && items.length > 0 && (
          <div>
            {items.map(item => (
              <div key={item.id} className={classes.testimonial}>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Box display="flex" flex={1} flexDirection="column">
                    <Hidden mdUp>
                      <Box mb={2} display="flex" justifyContent="center">
                        <Avatar
                          src={item.image?.url}
                          alt={`${item.name} Bewertung`}
                          className={classes.avatar}
                          imgProps={{
                            loading: 'lazy'
                          }}
                        >
                          {item.name[0]}
                        </Avatar>
                      </Box>
                    </Hidden>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box className={classes.quote}>
                        <span
                          className={clsx(
                            classes.quoteIcon,
                            classes[`quoteIcon${cssColorMappedClass}`]
                          )}
                        >
                          “
                        </span>
                      </Box>
                      <Typography
                        variant="h5"
                        component="h4"
                        color={colorMapped}
                      >
                        {item.url
                          ? (
                            <Link href={item.url}>
                              <a target="_blank" rel="noopener nofollow">
                                {item.name}
                              </a>
                            </Link>
                          )
                          : item.name}
                      </Typography>
                    </Box>
                    <Box className={classes.textContainer}>
                      <Typography
                        variant="body2"
                        color={colorMapped}
                        style={{ fontStyle: 'italic' }}
                      >
                        {item.text}
                      </Typography>
                      {item.url && (
                        <Box mt={3}>
                          <Link href={item.url}>
                            <Button
                              color="primary"
                              variant="contained"
                              component="a"
                              target="_blank"
                              rel="noopener nofollow"
                            >
                              Mehr
                            </Button>
                          </Link>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Hidden smDown>
                    <Box ml={3}>
                      <Avatar
                        src={item.image?.url}
                        alt={`${item.name} Bewertung`}
                        className={classes.avatar}
                        imgProps={{
                          loading: 'lazy'
                        }}
                      >
                        {item.name[0]}
                      </Avatar>
                    </Box>
                  </Hidden>
                </Box>
                <Box display="flex" mt={5} justifyContent="center">
                  <Divider
                    className={classes.divider}
                  />
                </Box>
              </div>
            ))}
          </div>
        )}
        <Box mt={10}>
          <BrandAttentionBar
            isProvenExpertVisible
            isSloganVisible
          />
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main
  },
  rootLight: {
    backgroundColor: theme.palette.light.main
  },
  rootDark: {
    backgroundColor: theme.palette.dark.main
  },
  container: {
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  testimonial: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3)
    },
    '&:last-child $divider': {
      display: 'none'
    }
  },
  divider: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    width: theme.spacing(10)
  },
  quote: {
    position: 'relative',
    width: 40,
    height: 40,
    marginRight: theme.spacing(1)
  },
  quoteIcon: {
    position: 'absolute',
    top: -7,
    left: 0,
    display: 'block',
    marginRight: theme.spacing(1),
    fontSize: 100,
    lineHeight: '100px',
    opacity: 0.25
  },
  quoteIconInherit: {
    color: theme.palette.secondary.contrastText
  },
  quoteIconPrimary: {
    color: theme.palette.secondary.contrastText
  },
  quoteIconSecondary: {
    color: theme.palette.primary.contrastText
  },
  textContainer: {
    flex: 1,
    paddingLeft: 40 + theme.spacing(1),
  },
  avatar: {
    width: 100,
    height: 100
  },
  sealImage: {
    width: 260
  },
  provenExpertRating: {
    transform: 'scale(1.25)',
  }
}));

CustomerTestimonialsBlock.typeName = 'ComponentContentContentBlockCustomerTestimonials'; // Strapi element type
CustomerTestimonialsBlock.propTypes = {
  data: PropTypes.shape({
    tags: PropTypes.string,
    color_scheme: ColorScheme.propTypes,
    employee: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired
};
CustomerTestimonialsBlock.dataSchema = yup.object().shape({
  tags: yup.string().nullable(),
  color_scheme: ColorScheme.dataSchema.nullable(),
  employee: yup.object().shape({
    id: yup.number().nullable()
  }).nullable()
});
CustomerTestimonialsBlock.graphQlSchema = `
... on ${CustomerTestimonialsBlock.typeName} {
  id
  tags
  color_scheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;

const FETCH_ADDITIONAL_DATA = gql`
query GetItems($where: JSON, $sort: String) {
  customertestimonials(where: $where, sort: $sort) {
    id
    name
    text
    tag
    url
    index
    image {
      name
      width
      height
      formats
      url
    }
  }
}
`;

const MUI_COLOR_MAPPING = {
  default: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'inherit',
  quaternary: 'inherit',
  light: 'inherit',
  dark: 'secondary'
};
