export default {
  salutation: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  topic: '',
  source: '',
  message: '',
  isTermsAndPrivacyAccepted: false
};
