import * as yup from 'yup';

import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';

import Button from '../atoms/Button';
import Container from '../atoms/Container';
import InterestRateDevelopment from '../molecules/InterestRateDevelopment';
import Link from '../atoms/Link';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';

export default function InterestRateDevelopmentBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    InterestRateDevelopmentBlock.dataSchema
  );
  const {
    is_chart_only
  } = elementData;

  if (is_chart_only) {
    return (
      <div className={classes.root}>
        <Container className={classes.container}>
          <InterestRateDevelopment />
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>
              Aktuelles Zinsniveau
            </Typography>
            <Typography>
              Finden Sie die passende Finanzierung für Ihre Wunsch-Immobilie. Wir zeigen Ihnen in unserem Chart die Zinsentwicklung der letzten Jahre, damit Sie gut informiert die richtige finanzielle Entscheidung treffen können.
            </Typography>
            <Box mt={5}>
              <Link href="/zinsentwicklung">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Jetzt über Zinsen informieren
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <InterestRateDevelopment />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.quaternary.light
  },
  container: {
    overflow: 'hidden'
  },
}));

InterestRateDevelopmentBlock.typeName = 'ComponentContentContentBlockInterestRateDevelopment'; // Strapi element type
InterestRateDevelopmentBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired
};
InterestRateDevelopmentBlock.dataSchema = yup.object().shape({
});
InterestRateDevelopmentBlock.graphQlSchema = `
... on ${InterestRateDevelopmentBlock.typeName} {
  id
  is_chart_only
}
`;
